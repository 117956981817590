export default defineNuxtRouteMiddleware((to, from) => {
  if (to.path !== '/' && to.path.endsWith('/')) {
    const { path, query, hash } = to;
    const nextPath = path.replace(/\/+$/, '') || '/';
    const nextRoute = { path: nextPath, query, hash };
    return navigateTo(nextRoute, { redirectCode: 301 });
  }

  if (to.path === '/contact-us-2') {
    return navigateTo('/contact-us', { redirectCode: 301 });
  }

  if (to.path === '/integration') {
    return navigateTo('/integrations', { redirectCode: 301 });
  }

  if (to.path === '/inconsistent-product-page-visits-cause-of-customer-churn') {
    return navigateTo('/blog/inconsistent-product-page-visits-cause-of-customer-churn', { redirectCode: 301 });
  }

  if (to.path === '/blog/number-of-tickets-vs-product-usage-predicts-customer-churn') {
    return navigateTo('/blog/how-zapscale-predicts-customer-churn', { redirectCode: 301 });
  }

  if (to.path === '/number-of-tickets-vs-product-usage-predicts-customer-churn') {
    return navigateTo('/blog/how-zapscale-predicts-customer-churn', { redirectCode: 301 });
  }

  if (to.path === '/late-payments-a-harbinger-to-customer-churn') {
    return navigateTo('/blog/late-payments-a-harbinger-to-customer-churn', { redirectCode: 301 });
  }

  if (to.path === '/blog/www.zapscale.com') {
    return navigateTo('/', { redirectCode: 301 });
  }

  if (to.path === '/blog/starting-up-again-to-make-customer-success-easy') {
    return navigateTo('/blog/why-young-start-ups-need-customer-success', { redirectCode: 301 });
  }

  if (to.path === '/blog/15-reasons-for-customer-churn/www.zapscale.com') {
    return navigateTo('/blog/15-reasons-for-customer-churn', { redirectCode: 301 });
  }

  if (to.path === '/blog/net-revenue-retention-key-indicator-of-successful-business') {
    return navigateTo('/blog/net-revenue-retention-key-indicator-of-a-successful-business', { redirectCode: 301 });
  }

  if (to.path === '/blog/customer-success-meetup') {
    return navigateTo('/blog/customer-success-meetup-bengaluru', { redirectCode: 301 });
  }

  if (to.path === '/poor-feature-fitment-cause-of-customer-churn') {
    return navigateTo('/blog/inconsistent-product-usage-cause-of-customer-churn', { redirectCode: 301 });
  }

  if (to.path === '/unique-challenges-of-a-saas-start-up') {
    return navigateTo('/blog/unique-challenges-of-a-saas-start-up', { redirectCode: 301 });
  }

  if (to.path === '/blog/customer-centric-onboarding') {
    return navigateTo('/podcasts/customer-centric-onboarding', { redirectCode: 301 });
  }

  if (to.path === '/blog/startup-growth-through-customer-success') {
    return navigateTo('/podcasts/startup-growth-through-customer-success', { redirectCode: 301 });
  }

  if (to.path === '/blog/prioritizing-customer-success-early-days') {
    return navigateTo('/podcasts/prioritizing-customer-success-early-days', { redirectCode: 301 });
  }

  if (to.path === '/blog/mastering-customer-success-metrics') {
    return navigateTo('/podcasts/mastering-customer-success-metrics', { redirectCode: 301 });
  }

  if (to.path === '/solutions/CSM') {
    return navigateTo('/solutions/customer-success-managers', { redirectCode: 301 });
  }

  if (to.path === '/podcasts/www.zapscale.com') {
    return navigateTo('/podcasts', { redirectCode: 301 });
  }

  if (to.path === '/podcasts/mastering-the-art-of-customer-journey-mapping-w-mike-sasaki') {
    return navigateTo('/podcasts/customer-journey-mapping', { redirectCode: 301 });
  }

  if (to.path === '/solutions/CSLead') {
    return navigateTo('/solutions/customer-success-heads', { redirectCode: 301 });
  }

  if (to.path === '/blog/customer-satisfaction-score-csat') {
    return navigateTo('/blog/customer-satisfaction-score', { redirectCode: 301 });
  }

  if (to.path === '/podcasts/how-to-nail-upselling-in-customer-success-w-rachel-provan') {
    return navigateTo('/podcasts/upselling-customer-success', { redirectCode: 301 });
  }

  if (to.path === '/blog/net-revenue-retention-key-indicator-of-successful-business/feed/') {
    return navigateTo('/blog/net-revenue-retention-key-indicator-of-a-successful-business', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/net-revenue-retention-key-indicator-of-successful-business/feed') {
    return navigateTo('/blog/net-revenue-retention-key-indicator-of-a-successful-business', {
      redirectCode: 301,
    });
  }

  if (to.path === '/solutions/CXO') {
    return navigateTo('/solutions/founders-and-cxos', {
      redirectCode: 301,
    });
  }

  if (to.path === '/inconsistent-product-usage-cause-of-customer-churn/') {
    return navigateTo('/blog/inconsistent-product-usage-cause-of-customer-churn', {
      redirectCode: 301,
    });
  }

  if (to.path === '/inconsistent-product-usage-cause-of-customer-churn') {
    return navigateTo('/blog/inconsistent-product-usage-cause-of-customer-churn', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/performance-marketing-customer-success/feed/') {
    return navigateTo('/blog/performance-marketing-customer-success', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/performance-marketing-customer-success/feed') {
    return navigateTo('/blog/performance-marketing-customer-success', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/customer-journey-mapping') {
    return navigateTo('/podcasts/customer-journey-mapping', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/online-presence-in-customer-success') {
    return navigateTo('/podcasts/online-presence-in-customer-success', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/early-stage-startup-mistakes') {
    return navigateTo('/podcasts/early-stage-startup-mistakes', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/upselling-customer-success') {
    return navigateTo('/podcasts/upselling-customer-success', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/documentation-customer-success') {
    return navigateTo('/podcasts/documentation-customer-success', {
      redirectCode: 301,
    });
  }

  if (to.path === '/compare') {
    return navigateTo('/alternative', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/customer-success-platforms-for-effective-customer-success/') {
    return navigateTo('/blog/customer-success-software', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/www.zapscale.com/pricing') {
    return navigateTo('/pricing', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/product-outcome-metrics-hr-tech') {
    return navigateTo('/webinars/product-outcome-metrics-hr-tech', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/customer-health-zapscale') {
    return navigateTo('/webinars/customer-health-zapscale', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/customer-success-investment-thesis') {
    return navigateTo('/webinars/customer-success-investment-thesis', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar') {
    return navigateTo('/webinars', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/customer-health') {
    return navigateTo('/webinars/customer-health-zapscale', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/customer-success-data-automation') {
    return navigateTo('/webinars/customer-success-data-automation', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/churn-impact-arr') {
    return navigateTo('/webinars/churn-impact-arr', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/churn-impact-arr') {
    return navigateTo('/webinars/churn-impact-arr', {
      redirectCode: 301,
    });
  }

  if (to.path === '/images/Header_logo.webp') {
    return navigateTo('/_ipx/s_420x34/images/header/zapscale-logo-header.webp', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/transforming-your-startup-with-a-customer-centric-mindset') {
    return navigateTo('/webinars/customer-centric-mindset', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/5-must-know-tips-for-newly-appointed-customer-success-managers') {
    return navigateTo('/blog/customer-success-manager-skills', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/customer-success-toolstack/feed/') {
    return navigateTo('/blog/customer-success-tech-stack-2024', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/category/zapscale/') {
    return navigateTo('/blog', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/www.gong.io') {
    return navigateTo('/blog', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/customer') {
    return navigateTo('/blog', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/reduce-customer') {
    return navigateTo('/blog', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/scaling-customer-experiences') {
    return navigateTo('/webinars/scaling-customer-experiences', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/zapscale-customer-visibility') {
    return navigateTo('/webinars/zapscale-customer-visibility', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/artificial-intelligence-customer-success') {
    return navigateTo('/webinars/artificial-intelligence-customer-success', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/startup-customer-success') {
    return navigateTo('/webinars/startup-customer-success', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinars/www.zapscale.com') {
    return navigateTo('/webinars', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/customer-centric-mindset') {
    return navigateTo('/webinars/customer-centric-mindset', {
      redirectCode: 301,
    });
  }

  if (to.path === '/webinar/transforming-your-startup-with-a-customer-centric-mindset-rick-adams-and-ankit-aggarwal') {
    return navigateTo('/webinars/customer-centric-mindset', {
      redirectCode: 301,
    });
  }

  if (to.path === '/podcasts/how-to-nail-upselling-in-customer-success-w-rachel-provan') {
    return navigateTo('/podcasts/upselling-customer-success', {
      redirectCode: 301,
    });
  }

  if (to.path === '/blog/net-revenue-retention-key-indicator-of-successful-business/feed/') {
    return navigateTo('/blog/net-revenue-retention-key-indicator-of-a-successful-business', {
      redirectCode: 301,
    });
  }
});
